import React, { useState, useEffect, useLayoutEffect } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useLocation } from "@reach/router"; // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

import IconChevronBottom from "../icons/chevron-bottom-16.svg";

import ButtonText from "../components/ButtonText";
import Logo from "../images/icon-transparent.svg";
import LogoFull from "../images/logo.svg";

import { cookiesbarCompData } from "../data/components-cookiesbar.data";
import { Link } from "gatsby";
import { conforms } from "lodash";

const Cookiesbar = () => {
  const {
    header,
    desc,
    buttonText,
    hideDetailsText,
    showDetailsText,
    learnMoreText,
    learnMoreLink,
    necessaryCookiesText,
    cookiesGroups,
  } = cookiesbarCompData();

  // Set current location from React Router
  const location = useLocation();

  // States
  const [expanded, setExpanded] = useState(false);
  const [visible, setVisible] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies([
    "cookies-allowed",
    "gdpr-necessary",
    "gdpr-optional",
  ]);

  // Handlers
  const handleAllowClick = () => {
    setCookie("gdpr-necessary", "true", { path: "/" });
    setCookie("gdpr-optional", "true", { path: "/" });

    acceptGpdr();
  };

  const handleNecessaryClick = () => {
    setCookie("gdpr-necessary", "true", { path: "/" });

    acceptGpdr();
  };

  // Functions
  const toggleDetails = () => {
    setExpanded(!expanded);
  };

  const acceptGpdr = () => {
    // Initialize Analitycs tools (GTM & HotJar)
    setCookie("cookies-allowed", "true", { path: "/" });
    initializeAndTrack(location);
    setExpanded(false);
    setVisible(false);
  };

  useLayoutEffect(() => {
    setVisible(cookies["cookies-allowed"] !== "true");
  }, [cookies, setVisible]);
  return (
    <>
      {visible && (
        <div className={`cookiesbar`}>
          <div className="cookiesbar__content">
            <div className="cookiesbar__wrapper">
              <div className="cookiesbar__main">
                <Col lg="24">
                  <LogoFull className="cookiesbar__logo" />

                  <p className="cookiesbar__header">{header}</p>
                  <p className="cookiesbar__desc">{desc}</p>
                </Col>
              </div>

              {expanded && (
                <div className="cookiesbar__details-wrapper">
                  <div className="cookiesbar__details">
                    {cookiesGroups &&
                      cookiesGroups.map(
                        ({ header, desc, cookiesItems }, index) => (
                          <div key={index} className="cookiesbar__group">
                            <p className="cookiesbar__group-header">
                              {header} ({cookiesItems.length})
                            </p>
                            <p className="cookiesbar__group-desc">{desc}</p>

                            <div className="cookiesbar__table-wrapper">
                              <table className="cookiesbar__table">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Provider</th>
                                    <th>Purpose</th>
                                    <th>Expiry</th>
                                    <th>Type</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {cookiesItems.map(
                                    (
                                      { expiry, name, provider, purpose, type },
                                      index
                                    ) => (
                                      <tr key={index}>
                                        <td>
                                          <strong>{name}</strong>
                                        </td>
                                        <td>
                                          <strong>{provider}</strong>
                                        </td>
                                        <td className="-huge">{purpose}</td>
                                        <td>{expiry}</td>
                                        <td>{type}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>
              )}

              <div className="cookiesbar__footer">
                {/* <button
                    className={`cookiesbar__button -toggle ${
                      expanded ? "-expanded" : ""
                    }`}
                    onClick={toggleDetails}
                  >
                    {expanded ? hideDetailsText : showDetailsText}
                    <IconChevronBottom className="cookiesbar__button-icon" />
                  </button> */}
                <ButtonText
                  variant="red,slim"
                  className="cookiesbar__button cmt-3 cmb-3 cmt-lg-0 cmb-lg-0"
                  onClick={handleAllowClick}
                >
                  {buttonText}
                </ButtonText>
                <div className="cookiesbar__footer-links">
                  {learnMoreLink && learnMoreText && (
                    <a
                      href={learnMoreLink.url}
                      title={learnMoreLink.title}
                      target={learnMoreLink.target}
                      className={`cookiesbar__button -details`}
                    >
                      {learnMoreText}
                    </a>
                  )}

                  <button
                    className={`cookiesbar__button -necessary cml-3 ${
                      expanded ? "-expanded" : ""
                    }`}
                    onClick={handleNecessaryClick}
                  >
                    {necessaryCookiesText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cookiesbar;
